import LogoAmazon from "../../assets/images/png/partner-amazon-logo-white.png";
import LogoBovcontrol from "../../assets/images/png/partner-bovcontrol-logo-white.png";
import LogoTheNature from "../../assets/images/png/partner-the-nature-logo-white.png";
import LogoWorldAgro from "../../assets/images/png/partner-world-agro-logo-white.png";
import IconSabia from "../../assets/images/svg/IconSabiaWhite";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./footer-styles";
import { FooterTranslate } from "./footer-translate";
export const Footer = () => {
  const { translate } = useTranslateDocument(FooterTranslate);
  const PartnersLogo = [
    {
      logo: LogoTheNature,
      url: "https://www.tnc.org.br/",
    },
    {
      logo: LogoWorldAgro,
      url: "https://www.worldagroforestry.org/country/Brazil",
    },
    {
      logo: LogoAmazon,
      url: "https://www.amazon.com/",
    },
    {
      logo: LogoBovcontrol,
      url: "https://platform.bovcontrol.com/",
    },
    // ,
    // {
    //   logo: './png/partner-mantle-logo-white.png',
    //   url: 'https://www.mantle-labs.com/'
    // }
  ];

  const ItemsMenu = [
    {
      label: "item_menu_1",
      url: "/about-us",
    },
    {
      label: "item_menu_4",
      url: "/faq",
    },
    {
      label: "item_menu_5",
      url: "/registre_now",
    },
  ];
  return (
    <S.Container>
      <S.Content>
        <S.WrapperInfo>
          <S.WrapperLogo>
            <IconSabia />
          </S.WrapperLogo>
          <S.InfoDescriptionText>
            {translate("copyright")}
          </S.InfoDescriptionText>
        </S.WrapperInfo>

        <S.WrapperMenu>
          <S.Menu>
            {ItemsMenu.map((item, key) => (
              <S.OptionMenu key={key} to={item.url}>
                {translate(item.label)}
              </S.OptionMenu>
            ))}
          </S.Menu>
        </S.WrapperMenu>
      </S.Content>
      <S.Copyright dangerouslySetInnerHTML={{ __html: translate('copy') }}/>
    </S.Container>
  );
};
