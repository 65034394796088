import React from 'react'

const IconHamburger = () => (
  <svg viewBox="0 0 512 512" style={{ minWidth: `2rem`, maxWidth: `2rem` }}>
    <path
      style={{ stroke: `var(--brownByoma)` }}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="48"
      d="M88 152h336M88 256h336M88 360h336"
    />
  </svg>
)

export default IconHamburger
