import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--greenByoma);
  display: flex;
  width: 100%;
  min-height: 240px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 200px;
  }
`;

export const ContentCardBannerTop = styled.div`
  max-width: 1260px;
  width: 100%;
  display: flex;
  flex-direction: column;
background-color: var(--greenByoma);
`;

export const Title = styled.h2`
  margin-bottom: 0;
  font-family: "Roboto-Regular";
  color: var(--offWhite);
  margin-left: 100px;
  font-size: 18px;
  line-height: 32px;

  @media (max-width: 768px) {
    margin-left: 30px;
  }
`;

export const SubTitle = styled.h1`
  color: var(--offWhite);
  text-align: left;
  margin-left: 100px;
  margin-top: 1px;
  font-family: "Roboto-Regular";
  font-weight: 600;
  font-size: 38px;
  line-height: 38px;
  letter-spacing: -1px;

  @media (max-width: 768px) {
    margin-left: 30px;
    font-size: 32px;
  }
`;
