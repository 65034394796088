import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ArrowDownBold from "../../assets/images/svg/Happy";
import IconHamburger from "../../assets/images/svg/IconHamburger";
import IconSabia from "../../assets/images/svg/IconSabia";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import { SelectLanguage } from "../select-language";
import * as S from "./header-styles";
import { headerTranslate } from "./header-translate";

export const Header = () => {
  const location = useLocation();
  const { translate } = useTranslateDocument(headerTranslate);
  const [showMenu, setShowMenu] = useState(false);

  const ItemsMenu = [
    {
      label: "item_menu_1",
      url: "/about-us",
    },
    {
      label: "item_menu_4",
      url: "/faq",
    },
  ];

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return (
    <S.Container>
      <S.Content>
        <S.WrapperLogo to={"/"}>
          <IconSabia />
        </S.WrapperLogo>
        <S.WrapperMenuHamburguer onClick={() => setShowMenu(!showMenu)}>
          <IconHamburger />
        </S.WrapperMenuHamburguer>
        <S.WrapperMenuOpition visible={showMenu}>
          {ItemsMenu.map((item, key) => (
            <S.CustomLink
              key={key}
              active={location.pathname == item.url}
              to={item.url}
            >
              {translate(item.label)}
            </S.CustomLink>
          ))}

          <S.ButtonRegister to={"/register"}>
            {translate("item_menu_5")}
            <ArrowDownBold />
          </S.ButtonRegister>
          <SelectLanguage />
        </S.WrapperMenuOpition>
      </S.Content>
    </S.Container>
  );
};
