export const FooterTranslate = {
  EN: {
    copyright:
      "Sabiá is a digital tool that supports the Agroforestry and Restoration Accelerator, combining agroforestry practices and native forest restoration with improved traditional farming or grazing operations.",
    item_menu_1: "What is Sabiá?",
    item_menu_2: "Learning Kit",
    item_menu_3: "Simulate Your Return",
    item_menu_4: "FAQ",
    item_menu_5: "Register Now",
    copy: 'Copyright &copy; 2024. All rights reserved.'
  },
  PT: {
    copyright:
      "O Sabiá é uma ferramenta digital que apoia o Acelerador de Agroflorestas e Restauração, combinando práticas agroflorestais e de restauração de florestas nativas com práticas melhoradas de agricultura tradicional e manejo da pastagem.",
    item_menu_1: "O que é Sabiá?",
    item_menu_2: "Kit de Aprendizagem",
    item_menu_3: "Simule seu Retorno",
    item_menu_4: "Dúvidas Frequentes",
    item_menu_5: "Inscreva-se",    
    copy: '<p>Copyright &copy; 2024. Todos os direitos reservados.</p>'
  },
};
