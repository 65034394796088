import { BrowserRouter } from "react-router-dom";
import "./shared/styles/global.css";

import { Container } from "./components/template/container/container";
import { AppProvider } from "./hooks";
import { AppRoutes } from "./routes/app.routes";
import GlobalStyles from "./shared/styles/GlobalStyles";

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <GlobalStyles />
        <Container>
          <AppRoutes />
        </Container>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
