import ArrowDownRight from "../../assets/images/svg/ByomaBrownArrowRight";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./card-banner-register-styles";
import { CardBannerRegisterTranslate } from "./card-banner-register-translate";

export const CardBannerRegister = () => {
  const { translate } = useTranslateDocument(CardBannerRegisterTranslate);

  return (
    <S.Container>
      <S.ContentCardBannerNextRegister>
        <S.TitleNextRegister>{translate("title_2")}</S.TitleNextRegister>
        <S.ButtonRegister to={"/register"}>
          {translate("cta_register")}
          <ArrowDownRight />
        </S.ButtonRegister>
      </S.ContentCardBannerNextRegister>
    </S.Container>
  );
};
