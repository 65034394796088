import { Link } from "react-router-dom";
import styled from "styled-components";
import sabiaAboutUsHero from "../../assets/images/jpg/sabia-about-us-hero.png";
import sabiaAboutProgram from "../../assets/images/jpg/sabia-about-us-program.jpg";
import sabiaAboutUsCycle from "../../assets/images/png/sabia-about-us-cycle.png";
import sabiaBird from "../../assets/images/webp/sabia-bird-about-us-hero.webp";
import Acelerator from "../../assets/images/svg/logo_acelerador.svg";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const CardBannerTop = styled.div`
  background-color: var(--greenByoma);
  display: flex;
  width: 100%;
  height: 240px;
  justify-content: center;
  align-items: center;
`;

export const ContentCardBannerTop = styled.div`
  max-width: 1260px;
  width: 100%;
`;

export const Title = styled.h2`
  margin-bottom: 0;
  font-family: "Roboto-Regular";
  color: var(--offWhite);
  margin-left: 100px;
  font-size: 18px;
  line-height: 32px;
`;

export const SubTitle = styled.h1`
  color: var(--offWhite);
  text-align: left;
  margin-left: 100px;
  margin-top: 1px;
  font-family: "Roboto-Regular";
  font-weight: 600;
  font-size: 38px;
  line-height: 38px;
  letter-spacing: -1px;
`;

export const CardBannerInfo = styled.div`
  background-color: var(--greenByoma);
  display: flex;
  width: 100%;
  height: 440px;
  justify-content: center;
  align-items: center;
  z-index: 0;

  > div {
    position: absolute;
    display: flex;
    max-width: 1268px;
  }
`;

export const ImageFarmerCocoa = styled.img.attrs({
  src: sabiaAboutUsHero,
})`
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: top;
`;

export const DescriptionProgram = styled.p`
  display: flex;
  color: var(--offWhite);
  font-size: 44px;
  font-weight: 600;
  margin-left: 30px;
  width: 70%;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

export const CardBannerAbout = styled.div`
  background-color: var(--white);
  display: flex;
  justify-content: center;
  min-height: 200px;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;
export const ContentCardBannerAbout = styled.div`
  display: flex;
  max-width: 1268px;
  flex-direction: row;
  justify-content: space-around;
  min-height: 600px;
  padding: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
`;

export const CreditPhoto = styled.p`
  color: var(--brownByoma);
  font-size: 12px;
  font-weight: 400;
  width: 70%;
`;

export const WrapperAboutDescription = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const AboutDescriptionApp = styled.div`
  width: 70%;
  color: var(--brownByoma);
  font-size: 26px;
  font-weight: 400;
`;

export const WrapperBoxLearnDirection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxLearnDirection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 380px;
  height: 300px;
  background: var(--offGray);
  border-radius: 8px;
  margin-left: 10px;
`;

export const HeaderBoxLearn = styled.div`
  display: flex;
  align-items: center;
  margin-left: 26px;
`;

export const TitleBoxLearn = styled.h2`
  color: var(--brownByoma);
  font-size: 34px;
  font-weight: 400;
  margin-left: 30px;
  width: 70%;
`;

export const ItemBoxLearn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 20px;
`;

export const TitleItemBoxLearn = styled(Link)`
  display: flex;
  width: 60%;
  text-decoration: none;
  color: var(--brownByoma);
  font-size: 20px;
  margin-left: 18px;
`;

export const TitlePartner = styled.h2`
  color: var(--brownByoma);
  font-size: 16px;
  margin-top: 30px;
`;

export const WrapperLogoPartner = styled.div`
  max-width: 1268px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LogoPartner = styled.a`
  margin: 0 30px;

  svg {
    width: 140px;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
    flex-direction: column;
  }
`;

export const CardBannerWhySabia = styled.div``;
export const ContentCardBannerWhySabia = styled.div`
  position: relative;
`;

export const BackgroundImgSabia = styled.img.attrs({
  src: sabiaBird,
})`
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: top;
`;

export const BoxBackground = styled.div`
  position: absolute;
  width: 500px;
  height: 480px;
  background-color: var(--white);
  opacity: 0.4;
  z-index: 1;
  top: 100px;
  right: 100px;
  -moz-transform: rotate(6deg);
  -webkit-transform: rotate(6deg);
  -o-transform: rotate(6deg);
  -ms-transform: rotate(6deg);
  transform: rotate(5deg);

  @media (max-width: 768px) {
    width: 350px;
    height: 390px;
    top: 150px;
    right: 30px;
  }
`;

export const BoxContentSabia = styled.div`
  position: absolute;
  width: 500px;
  height: 480px;
  z-index: 1;
  top: 100px;
  right: 100px;
  backdrop-filter: blur(22.3px);
  background-color: rgb(255 255 255 / 50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 350px;
    height: 390px;
    top: 150px;
    right: 30px;
  }
`;

export const TitleBoxSabia = styled.h1`
  color: var(--brownByoma);
  font-size: 30px;
  line-height: 34px;
  font-weight: bold;
  line-height: 1;
`;

export const DesciptionInfoBoxSabia = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--brownByoma);
  text-align: center;
  width: 80%;
`;

export const CardBannerProgram = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;

  > p {
    display: none;
    position: none;
    margin-top: -150px;
    margin-left: 100px;
  }
`;
export const ContentCardBannerProgram = styled.div`
  display: flex;
  max-width: 1269px;
  padding: 30px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ImageCocoa = styled.img.attrs({
  src: Acelerator,
})`
  margin-top: 40px;
  width: 100%;
  height: 90%;
`;

export const WrapperAboutProgram = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  padding-left: 60px;

  @media (max-width: 768px) {
    margin-left: 10px;
    padding-left: 10px;
  }
`;

export const TitleAboutProgrm = styled.h2`
  font-weight: 400;
  font-size: 38px;
  font-weight: 600;
  color: var(--greenByoma);
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const DescriptionAboutProgram = styled.p`
  font-weight: 500;
  font-size: 19px;
  color: var(--greenByoma);
  width: 80%;

  @media (max-width: 768px) {
    text-align: center;
    width: 90%;
    margin-bottom: 100px;
  }
`;

export const CardBannerAboutCycle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
export const ContentCardBannerAboutCycle = styled.div`
  display: flex;
  max-width: 1269px;
  padding: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
export const ImageCycle = styled.img.attrs({
  src: sabiaAboutUsCycle,
})`
  position: absolute;
  margin-top: 70px;
  /* position: relative; */
  width: 90%;
  height: 800px;
  object-fit: cover;
  object-position: center;
  z-index:0;

  @media (max-width: 768px) {
    /* object-fit: scale-down; */
    width: 100%;
  }
`;

export const WrapperFormQuestion = styled.div`
/* position: relative; */
  display: flex;
  flex-direction: column;
  width: 900px;
  z-index:10;
  margin-top: 120px;

  @media (max-width: 768px) {
    /* margin-top: 80px; */
    width: 90%;
    height: 100%;
  }
`;

export const TitleForm = styled.h2`
  font-weight: 400;
  font-size: 42px;
  font-weight: 600;
  color: var(--greenByoma);
  text-align: center;
`;

export const WrapperQuestion = styled.div``;
export const HeaderQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;
export const OrderQuestion = styled.h2`
  color: var(--greenByoma);
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const TitleQuestion = styled.h2`
  display: flex;
  width: 100%;
  font-size: 22px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

interface ContentResponseProps {
  visible: boolean;
}

export const ContentResponse = styled.div<ContentResponseProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  width: 100%;
  max-width: 800px;
  margin-top: -16px;
  align-items: flex-start;
  margin-left: 48px;

  @media (max-width: 768px) {
    margin-left: 38px;
    width: 70%;
  }
`;

export const ResponseText = styled.p`
  margin-top: 1px;
  font-weight: 300;
  font-size: 18px;
  font-weight: 600;
  color: var(--brownByoma);

  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
  }
`;
