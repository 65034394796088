export const RegisterTranslate = {
  EN: {
    title: "Register",
    info_action: 'Download the App, register and start using it!',
    subtitle: "To register for Sabiá, simply follow these steps: click on your operating system below to download the app, and then complete the registration process directly within the app. You will be redirected to the appropriate app store to download it.",
  },
  PT: {
    title: "Inscreva-se",
    info_action: 'Baixe o App, cadastre-se e comece a usar!',
    subtitle:
      "Para se cadastrar no Sabiá, siga estes passos simples: baixe o aplicativo clicando na opção correspondente ao seu sistema operacional abaixo, e complete o processo de inscrição diretamente no app. Você será redirecionado para a loja de aplicativos apropriada para fazer o download.",
  },
};
