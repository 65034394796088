import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 1260px;
  /* min-width: 620px; */
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex:1;
  padding: 100px 18px 0px 18px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
`;

export const WrapperLogo = styled.div`
  > svg {
    width: 240px;
  }
  /* @media (max-width: 768px) {
    > svg {
    width: 200px;
  }
  } */
`;

export const InfoDescriptionText = styled.p`
  color: var(--offWhite);
  width: 75%;
  font-size: 12px;
`;

export const WrapperMenu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  width: 500px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 60px;
  }
`;

export const OptionMenu = styled(Link)`
  color: var(--offWhite);
  text-decoration: none;
  margin: 0 16px;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 768px) {
    margin: 10px;
  }
`;

export const WrapperLogoBusiness = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LogoBusiness = styled.img`
  width: 130px;
  margin: 0 18px;

  @media (max-width: 768px) {
    margin-top: 40px;
    width: 40%;
  }
`;

export const Copyright = styled.p`
  color: var(--offWhite);
  font-size: 12px;
  text-align: center;
  margin-top: 100px;

  @media (max-width: 768px) {
    position: relative;
    margin-top: 80px;
  }
`;
