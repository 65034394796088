import FaqApp from "../../assets/images/svg/FaqApp";
import FaqLogo from "../../assets/images/svg/FaqLogo";
import LogoAmazon from "../../assets/images/svg/sabia-partner-amazon-color";
import LogoBovcontrol from "../../assets/images/svg/sabia-partner-bovcontrol-color";
import LogoTheNature from "../../assets/images/svg/sabia-partner-the-nature-conservacy-color";
import LogoWorldAgro from "../../assets/images/svg/sabia-partner-world-agronomy-center-color";
import { CardBannerRegister } from "../../components/CardBannerRegister";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./about-us-styles";
import { AboutUsTranslate } from "./about-use-translate";

import { useState } from "react";
import FaqMinus from '../../assets/images/svg/FaqMinus';
import FaqPlus from '../../assets/images/svg/FaqPlus';
import { CardBannerTop } from "../../components/card-banner-top";

export const AboutUsPage = () => {
  const [openQuestion, setOpenQuestion] = useState('');
  const PartnersLogo = [
    {
      logo: LogoTheNature,
      url: "https://www.tnc.org.br/",
    },
    {
      logo: LogoWorldAgro,
      url: "https://www.worldagroforestry.org/country/Brazil",
    },
    {
      logo: LogoAmazon,
      url: "https://www.amazon.com/",
    },
    {
      logo: LogoBovcontrol,
      url: "https://platform.bovcontrol.com/",
    },
  ];

  const FaqQuestions = [
    {
      quest: "program-1",
      title: "cycle_item_title_1",
      answer: "cycle_item_subtitle_1",
      questNumber: "01",
    },
    {
      quest: "program-2",
      title: "cycle_item_title_2",
      answer: "cycle_item_subtitle_2",
      questNumber: "02",
    },
    {
      quest: "program-3",
      title: "cycle_item_title_3",
      answer: "cycle_item_subtitle_3",
      questNumber: "03",
    },
    {
      quest: "program-4",
      title: "cycle_item_title_4",
      answer: "cycle_item_subtitle_4",
      questNumber: "04",
    },
    {
      quest: "program-5",
      title: "cycle_item_title_5",
      answer: "cycle_item_subtitle_5",
      questNumber: "05",
    },
    {
      quest: "program-6",
      title: "cycle_item_title_6",
      answer: "cycle_item_subtitle_6",
      questNumber: "06",
    },
    {
      quest: "program-7",
      title: "cycle_item_title_7",
      answer: "cycle_item_subtitle_7",
      questNumber: "07",
    },
  ];

  const partnerItem = PartnersLogo.map((partner, key) => (
    <S.LogoPartner key={key} href={partner.url} target="_blank">
      {partner.logo()}
    </S.LogoPartner>
  ));

  const toggleOpenQuestion = (question: string) => {
    if(openQuestion === question) {
      setOpenQuestion("")
      return;
    }
    setOpenQuestion(question)
  }

  const { translate } = useTranslateDocument(AboutUsTranslate);
  return (
    <S.Container>
      <CardBannerTop subtitle={translate('title')}></CardBannerTop>
      <S.CardBannerInfo>
        <div>
          <S.DescriptionProgram>
            {translate("banner_title")}
          </S.DescriptionProgram>
        </div>
        <S.ImageFarmerCocoa sizes="" />
      </S.CardBannerInfo>
      <S.CardBannerAbout>
        {/* <S.CreditPhoto>{translate("photo_credits")}</S.CreditPhoto> */}
        <S.ContentCardBannerAbout>
          <S.WrapperAboutDescription>
            <S.AboutDescriptionApp
              dangerouslySetInnerHTML={{ __html: translate("main_content") }}
            />
          </S.WrapperAboutDescription>
          <S.WrapperBoxLearnDirection>
            <S.BoxLearnDirection>
              <S.HeaderBoxLearn>
                <S.TitleBoxLearn>
                  {translate("faq__help_title")}
                </S.TitleBoxLearn>
              </S.HeaderBoxLearn>
              <S.ItemBoxLearn>
                <FaqApp />
                <S.TitleItemBoxLearn to={"/faq"}>
                  {translate("faq__help_item1")}
                </S.TitleItemBoxLearn>
                {/* <FaqLogo/> */}
              </S.ItemBoxLearn>
              <S.ItemBoxLearn>
                <FaqLogo />
                <S.TitleItemBoxLearn to={"/register"}>
                  {translate("faq__help_item2")}
                </S.TitleItemBoxLearn>
              </S.ItemBoxLearn>
            </S.BoxLearnDirection>
          </S.WrapperBoxLearnDirection>
        </S.ContentCardBannerAbout>
        {/* <S.TitlePartner>{translate("partners")}</S.TitlePartner>
        <S.WrapperLogoPartner>{partnerItem}</S.WrapperLogoPartner> */}
      </S.CardBannerAbout>
      <S.CardBannerWhySabia>
        <S.ContentCardBannerWhySabia>
          <S.BackgroundImgSabia />
          <S.BoxBackground />
          <S.BoxContentSabia>
            <S.TitleBoxSabia>{translate("why_sabia")}</S.TitleBoxSabia>
            <S.DesciptionInfoBoxSabia
              dangerouslySetInnerHTML={{ __html: translate("about_sabia") }}
            />
          </S.BoxContentSabia>
        </S.ContentCardBannerWhySabia>
      </S.CardBannerWhySabia>
      <S.CardBannerProgram>
        <S.ContentCardBannerProgram>
          <S.ImageCocoa />
          <S.WrapperAboutProgram>
            <S.TitleAboutProgrm>
              {translate("program_subtitle")}
            </S.TitleAboutProgrm>
            <S.DescriptionAboutProgram>
              {translate("program_content")}
            </S.DescriptionAboutProgram>
          </S.WrapperAboutProgram>
        </S.ContentCardBannerProgram>
        {/* <S.CreditPhoto>{translate("photo_credits")}</S.CreditPhoto> */}
      </S.CardBannerProgram>
      <S.CardBannerAboutCycle>
        <S.ContentCardBannerAboutCycle>
          <S.ImageCycle />
          <S.WrapperFormQuestion>
            <S.TitleForm>{translate("cycle_title")}</S.TitleForm>
            {FaqQuestions.map((item, key) => (
              <S.WrapperQuestion>
                <S.HeaderQuestion onClick={() => toggleOpenQuestion(item.quest)}>
                  <S.OrderQuestion>{item.questNumber}</S.OrderQuestion>
                  <S.TitleQuestion>{translate(item.title)}</S.TitleQuestion>
                    { openQuestion === item.quest ? <FaqMinus/> :<FaqPlus/>}
                </S.HeaderQuestion>
                <S.ContentResponse visible={openQuestion === item.quest}>
                  <S.ResponseText>
                    {translate(item.answer)}
                    </S.ResponseText>
                </S.ContentResponse>
              </S.WrapperQuestion>
            ))}
          </S.WrapperFormQuestion>
        </S.ContentCardBannerAboutCycle>
      </S.CardBannerAboutCycle>
      <CardBannerRegister />
    </S.Container>
  );
};
