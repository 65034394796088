import React from 'react'

const ByomaBrownArrowRight = () => (
<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.793 0.293031C9.98053 0.10556 10.2348 0.000244141 10.5 0.000244141C10.7652 0.000244141 11.0195 0.10556 11.207 0.293031L18.207 7.29303C18.3945 7.48056 18.4998 7.73487 18.4998 8.00003C18.4998 8.26519 18.3945 8.5195 18.207 8.70703L11.207 15.707C11.0184 15.8892 10.7658 15.99 10.5036 15.9877C10.2414 15.9854 9.99059 15.8803 9.80518 15.6948C9.61977 15.5094 9.5146 15.2586 9.51233 14.9964C9.51005 14.7342 9.61084 14.4816 9.793 14.293L15.086 9.00003H1.5C1.23478 9.00003 0.98043 8.89467 0.792893 8.70714C0.605357 8.5196 0.5 8.26525 0.5 8.00003C0.5 7.73481 0.605357 7.48046 0.792893 7.29292C0.98043 7.10539 1.23478 7.00003 1.5 7.00003H15.086L9.793 1.70703C9.60553 1.5195 9.50021 1.26519 9.50021 1.00003C9.50021 0.734866 9.60553 0.480558 9.793 0.293031Z" fill="#332522"/>
</svg>

)

export default ByomaBrownArrowRight
