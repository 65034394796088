import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./card-banner-top-styles";
import { CardBannerTopTranslate } from "./card-banner-top-translate";

interface CardBannerTopProps {
  subtitle: string;
  children?: React.ReactNode;
}
export const CardBannerTop = ({subtitle, children }: CardBannerTopProps) => {
  const { translate } = useTranslateDocument(CardBannerTopTranslate);
  return (
    <S.Container>
      <S.ContentCardBannerTop>
        <S.Title>{translate("title")}</S.Title>
        <S.SubTitle>{subtitle}</S.SubTitle>
        {children}
      </S.ContentCardBannerTop>
    </S.Container>
  );
};
