export const FaqTranslate = {
  EN: {
    title: "Frequently Asked Questions",
    faq_title: "About Sabiá",
    faq_1_title: "Who can use Sabiá?",
    faq_1_content:
      "<p>Sabiá is available for free to farmers in Pará, Brazil who choose to participate in the Agroforestry and Restoration Accelerator.</p>",
    faq_2_title: "What features does Sabiá provide?",
    faq_2_content:
      "<p>Registered participants can use many of the app's features and gain access to new features as they are released, including:</li> <li>	Access to customized trainings;</li><li>	Connection to expert technicians who can assist in agroforestry techniques and improved pasture management;</li><li>	Assistance in forest restoration for environmental compliance.</li></ul>",
    faq_app_title: "About the Agroforestry and Restoration Accelerator",
    faq_app_1_title:
      "What are the objectives of the Agroforestry and Restoration Accelerator?",
    faq_app_1_content:
      "<p><b>The Agroforestry and Restoration Accelerator</b> is an initiative from Amazon to support agroforestry and restoration through the generation of carbon credits. The Accelerator aims to enhance livelihoods for family farmers by assisting them in establishing agroforestry systems and restoring native vegetation on part of their land. The systems can provide a profitable and sustainable source of income, and remove carbon from the atmosphere in the process.</b><p> Farmers who join the Agroforestry and Restoration Accelerator receive access to a wide variety of services - quickly and easily - through Sabiá. </b>.</p>",
    faq_app_2_title:
      "Who are the partners involved in the Agroforestry and Restoration Accelerator?",
    faq_app_2_content:
      "<p> <b>Amazon</b><br/> Provided the investment to launch the Agroforestry and Restoration Accelerator and is conducting high-level program management and oversight. Amazon is responsible for securing the validation, verification, issuance, and sale of carbon credits from project activities.</p><p> <b>Belterra</b><br/> Responsible for the local operation of the Agroforestry and Restoration Accelerator in Para. Belterra works with local organizations to provide training, technical assistance, and access to inputs to enrolled farmers.</p><p> <b>BovControl</b><br/> Manages the development and management of Sabiá. The app acts as a tool for onboarding participating farmers, facilitating digital communication between farmers and field technicians, and monitoring farmers progress in restoring their land.",
    faq_app_3_title:
      "How does the Agroforestry and Restoration Accelerator work?",
    faq_app_3_content:
      "<p> Once farmers enroll in the Agroforestry and Restoration Accelerator, they will be trained in agroforestry techniques by either attending a workshop on a demonstration farm or learning from a “lead farmer” in their area who has completed a rigorous previous training program.<br/><br/> An expert technician will help each farmer to develop an implementation plan specifically for their land. <br/><br/>As the farmers’ agroforestry systems produce cocoa and other crops, they may sell them to generate income, with access to new markets facilitated by the Accelerator. Meanwhile, as the trees grow, farmers and field technicians will take measurements to help the Accelerator assess how much carbon is being sequestered on their land and generate carbon credits. Twenty percent of the credits generated on each farmer’s property will be allocated to Amazon in order to cover its costs in measuring, monitoring, and verifying carbon sequestration across the project area. Farmers are entitled to the remainder of carbon credits generated on their property, and can either allocate a portion of these credits to Amazon in exchange for goods or services that support restoration,  or earn extra income by selling the credits to Amazon or other companies through the Agroforestry and Restoration Accelerator.",
    faq_app_4_title: "Why join the Agroforestry and Restoration Accelerator?",
    faq_app_4_content:
      "<p> Participation in the Agroforestry and Restoration Accelerator, which is voluntary, allows rural producers to increase their income through the development of agroforestry systems. The Accelerator provides farmers with access to training, technical assistance, and inputs such as seeds and seedlings to start their agroforestry systems. The Accelerator can also assist farmers with applying for rural credit to finance their investments in agroforestry and forest restoration. <br/><br/> Once operational, agroforestry systems are on average more profitable than grazing cattle, because they produce more high-value crops that can be sold in local and international markets. But farmers are also free to continue to manage pasture areas or other cropping systems on the remainder of their property, as long as they meet program requirements for agroforestry development and native forest restoration. ",
    faq_app_5_title:
      "Who can join the Agroforestry and Restoration Accelerator?",
    faq_app_5_content:
      "<p> To join the Agroforestry and Restoration Accelerator in Pará, farmers must: </p><ul> <li>	Be classified as small farmers, defined as operating a rural property with a maximum area of 4 rural modules (about 300 hectares), which must not overlap with Indigenous Lands, Conservation Units, or other protected areas; </li><li>	Not have engaged in deforestation on their property within the previous 5 years, or within the last 10 years on areas to be restored through the Accelerator;</li><li>	Not employ, or have a history of employing, child or slave labor on their property.</li></ul>",
    faq_app_6_title:
      "How can farmers enroll in the Agroforestry and Restoration Accelerator?",
    faq_app_6_content:
      "<p> Farmers can participate in the Agroforestry and Restoration Accelerator</b> by following 6 easy steps: </p><ul> <li>	Step 1: Learn about the Agroforestry and Restoration Accelerator and whether it’s right for you, for example by attending a seminar, talking to another participating farmer, or exploring content on Sabiá.<p> </li><li>	Step 2: Apply for the Agroforestry and Restoration Accelerator through Sabiá and verify that you meet all eligibility criteria for enrollment.<p> </li><li>	Step 3: Sign an agreement to participate in the project, which allows Amazon to perform carbon monitoring and generate carbon credits from agroforestry and forest systems on your property in return for a share of the carbon credits produced.<p> </li><li>	Step 4: Attend training modules to learn more about how to implement agroforestry and restoration activities.<p> </li><li>	Step 5: Meet with a technician to develop an implementation plan for your farm. <p></li><li>	Stage 6: Develop agroforestry systems and forest restoration, taking advantage of the benefits of the Agroforestry and Restoration Accelerator. </li></ul>",
    faq_app_7_title:
      "Still have questions?",
    faq_app_7_content:
      "<p>Contact us by <a href='mailto:sabia@bovcontrol.com'>sabia@bovcontrol.com</a></p>",
    faq__help_title: "Need help?",
    faq__help_item1: "<p>Questions about<br/>Sabiá</p>",
    faq__help_item2: "<p>Questions related<br/>to the Programs</p>",
    faq__help_item3a: "<p>By WhatsApp<br/>+55 11 9999.9999</p>",
    faq__help_item3b: "<p>By email<br/>sabia@bovcontrol.com</p>",
  },
  PT: {
    title: "Perguntas frequentes",
    faq_title: "Sobre o Sabiá",
    faq_1_title: "Quem pode usar o Sabiá?",
    faq_1_content:
      "<p>O Sabiá está disponível gratuitamente para agricultores no Pará, Brasil, que optarem por participar do Acelerador de Agroflorestas e Restauração.<p>",
    faq_2_title: "Quais recursos o Sabiá oferece?",
    faq_2_content:
      "<p>Os participantes inscritos podem usar muitos dos recursos do aplicativo e obter acesso a novos recursos à medida em que são lançados, incluindo:</p><ul> <li> Acesso a treinamentos personalizados; </li><li>Conexão com técnicos especializados que podem auxiliar em técnicas de produção agroflorestal e melhor manejo de pastagens;</li><li> Suporte para restauração florestal e adequação ambiental.",
    faq_app_title: "Sobre o Acelerador de Agroflorestas e Restauração",
    faq_app_1_title:
      "Quais são os objetivos do Acelerador de Agroflorestas e Restauração?",
    faq_app_1_content:
      "<p><b>O Acelerador de Agroflorestas e Restauração é uma iniciativa da Amazon para apoiar a adoção de sistemas agroflorestais e a restauração por meio da geração de créditos de carbono. O programa visa melhorar a qualidade de vida dos agricultores familiares, auxiliando-os na implantação de sistemas agroflorestais e na restauração da vegetação nativa em parte de suas terras. Os sistemas podem fornecer uma fonte de renda lucrativa e sustentável e remover o carbono da atmosfera no processo.</p><p> Agricultores que aderem ao Acelerador de Agroflorestas e Restauração passam a ter acesso a uma ampla variedade de serviços - de forma rápida e fácil - por meio do Sabiá.",
    faq_app_2_title:
      "Quem são os parceiros envolvidos no Acelerador de Agroflorestas e Restauração?",
    faq_app_2_content:
      "<p><b>Amazon</b><br/> Forneceu o investimento para lançar o Acelerador de Agroflorestas e Restauração e está conduzindo a gestão  e a supervisão do programa em alto nível. A Amazon é responsável por garantir a validação, verificação, emissão e venda de créditos de carbono das atividades do programa.<p><b>Belterra</b><br/> Responsável pela operação local do Acelerador de Agroflorestas e Restauração no Pará. A Belterra trabalha com organizações locais fornecendo treinamento, assistência técnica e acesso a insumos para os agricultores inscritos. <p><b>BovControl</b><br/> Gerencia o desenvolvimento e gestão do Sabiá. O aplicativo funciona como uma ferramenta para integrar os agricultores participantes, facilitando a comunicação digital entre agricultores e técnicos de campo e monitorando o progresso dos agricultores  na restauração de suas terras.",
    faq_app_3_title:
      "Como funciona o Acelerador de Agroflorestas e Restauração?",
    faq_app_3_content:
      "<p>Uma vez  que os agricultores se inscrevam no Acelerador de Agroflorestas e Restauração, eles serão treinados em técnicas agroflorestais ou participando de uma reunião de apresentação em uma propriedade demonstrativa ou aprendendo com um “agricultor líder” em sua área que já tenha participado de  um rigoroso programa de treinamento anterior.<br/><br/> Um técnico especialista ajudará cada agricultor a desenvolver um plano de implementação específico para sua propriedade.<br/><br/> Como os sistemas agroflorestais dos agricultores produzem cacau e outras culturas, eles podem vendê-los para gerar renda, por meio do acesso a novos mercados facilitado pelo Acelerador. Enquanto isso, à medida que as árvores crescem, agricultores e técnicos de campo farão medições para ajudar o programa a avaliar quanto carbono está sendo sequestrado em suas terras e gerar créditos de carbono. Vinte por cento dos créditos gerados em cada propriedade serão alocados para a Amazon com o objetivo de cobrir custos associados a medição, monitoramento e verificação dos créditos de carbono gerados nas áreas do projeto. Os agricultores detêm o restante dos créditos de carbono gerados na propriedade e podem ou alocar uma parte desses créditos para a Amazon em troca de bens ou serviços que apoiem restauração pelo Programa de Implementação por Carbono, ou obter uma renda extra vendendo os créditos para a Amazon ou outras empresas por meio do Acelerador de Agroflorestas e Restauração.",
    faq_app_4_title:
      "Por que participar do Acelerador de Agroflorestas e Restauração",
    faq_app_4_content:
      "<p>A participação no Acelerador de Agroflorestas e Restauração, que é voluntária, permite que os produtores rurais aumentem sua renda por meio do desenvolvimento de sistemas agroflorestais. O Acelerador oferece aos agricultores acesso a treinamento, assistência técnica e insumos como sementes e mudas para iniciar seus sistemas agroflorestais. O Acelerador também pode auxiliar os agricultores na solicitação de crédito rural para financiar seus investimentos em sistemas agroflorestais e na restauração florestal.<br/><br/> Uma vez operacionais, os sistemas agroflorestais são, em média, mais lucrativos do que a pecuária extensiva, porque produzem mais culturas de alto valor que podem ser vendidas nos mercados locais e internacionais. Contudo, os agricultores também são livres para continuar a manejar áreas de pastagem ou outros sistemas de cultivo no restante de sua propriedade, desde que atendam aos requisitos mínimos do programa de desenvolvimento agroflorestal e de restauração de florestas nativas.",
    faq_app_5_title:
      "Quem pode aderir ao Acelerador de Agroflorestas e Restauração?",
    faq_app_5_content:
      " <p>Para aderir ao Acelerador de Agroflorestas e Restauração no Pará, o agricultor deve: </p><ul> <li>	Ser enquadrado como pequeno produtor rural, definido como aquele que explora imóvel rural com área máxima de 4 módulos fiscais (em média até 300 hectares), e que não deve se sobrepor a Terras Indígenas, Unidades de Conservação, ou outras áreas protegidas; </li><li>	Não ter realizado desmatamento em sua propriedade  nos últimos 5 anos; </li><li>	Não ter realizado, nos últimos 10 anos, desmatamento nas áreas a serem restauradas com apoio do Acelerador; </li><li>	Não empregar, ou ter histórico de utilização de trabalho infantil, ou trabalho escravo em sua propriedade. ",
    faq_app_6_title:
      "Como os agricultores podem se inscrever no Acelerador de Agroflorestas e Restauração?",
    faq_app_6_content:
      "<p><ul> <li> <b>Passo 1:<b/> Saiba mais sobre o Acelerador de Agroflorestas e Restauração e se ele é adequado para você, por exemplo, participando de uma reunião de apresentação, conversando com outro produtor participante ou explorando o conteúdo no Sabiá. <br/><br/></li><li> Passo 2: Registre-se no Acelerador de Agroflorestas e Restauração através do Sabiá e verifique se você atende a todos os critérios de elegibilidade para adesão.<br/><br/></li><li> Passo 3: Assine um contrato para participação  no programa, que permite à Amazon realizar o monitoramento de carbono e gerar créditos de carbono de sistemas agroflorestais e restauração florestal em sua propriedade em troca de uma parte dos créditos de carbono produzidos.<br/><br/></li><li>	Passo 4: Participe de módulos de treinamento para aprender mais sobre como implementar sistemas agroflorestais e restauração de florestas.<br/><br/></li><li>	Passo 5: Reúna-se com um técnico para desenvolver um plano de implementação para sua propriedade.<br/><br/></li><li>	Etapa 6: Desenvolva sistemas agroflorestais e restauração florestal, aproveitando os benefícios do Acelerador de Agroflorestas e Restauração.",
    faq_app_7_title: "Ainda possui dúvidas?",
    faq_app_7_content: "<p>Entre em contato pelo sabia@bovcontrol.com</p>",
    faq__help_title: "Precisa de ajuda?",
    faq__help_item1: "<p>Dúvidas sobre<br/>Sabiá</p>",
    faq__help_item2: "<p>Dúvidas relacionadas<br/>para os programas</p>",
    faq__help_item3a: "<p>Por WhatsApp<br/>+55 11 9999.9999</p>",
    faq__help_item3b: "<p>Por e-mail<br/>sabia@bovcontrol.com</p>",
  },
};
