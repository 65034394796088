import { Footer } from "../../footer";
import { Header } from "../../header";
import * as S from "./container-styles";

interface ContainerProps {
  children?: React.ReactNode;
}

export const Container = ({children}: ContainerProps) => {
  return (
    <S.Container>
      <S.WrapperHeader>
        <Header />
      </S.WrapperHeader>
      <S.WrapperBody>{children}</S.WrapperBody>
      <S.WrapperFooter>
        <Footer/>
      </S.WrapperFooter>
    </S.Container>
  );
};
