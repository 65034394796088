import { useTranslate } from "./use-translate";

export const useTranslateDocument = (document: any) => {
  const { language } = useTranslate();

  const translate = (key: string): string => {
    try {
      if (!document) return "not imported document";
      if (!key) return "-";
      const find_key_in_document = Object.keys(
        document[language.toUpperCase()]
      ).find((item) => item === key);
      if (!find_key_in_document) return "";
      return document[language][key];
    } catch (error: any) {
      return "-";
    }
  };

  return { translate };
};
