export const headerTranslate = {
  EN: {
    item_menu_1: "What is Sabiá?",
    item_menu_2: "Learning Kit",
    item_menu_3: "Simulate Your Return",
    item_menu_4: "FAQ",
    item_menu_5: "Register Now",
  },
  PT: {
    item_menu_1: "O que é Sabiá?",
    item_menu_2: "Kit de Aprendizagem",
    item_menu_3: "Simule seu Retorno",
    item_menu_4: "Dúvidas Frequentes",
    item_menu_5: "Inscreva-se",
  },
};
