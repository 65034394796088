import { Navigate, Route, Routes } from "react-router-dom";
import { AboutUsPage } from "../pages/about-us/about-us-page";
import { FaqPage } from "../pages/faq";
import { HomePage } from "../pages/home";
import { RegisterPage } from "../pages/register";
import React from "react";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/register" element={<RegisterPage />} />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
