export const HomeTranslate = {
  EN: {
    title_1:
      "Discover new opportunities. \n Discover agroforestry. \n Discover Sabiá. ",
    title_2: "Easy to use and built for family farmers.",
    cta: "LEARN MORE",
    brazil: "PARÁ, BRAZIL",
    content_h2:
      "Sabiá helps family farmers implement agroforestry systems and restore forests.",
    content:
      "Farmers who enroll in the Agroforestry and Restoration Accelerator can use Sabiá for easy access to many program services.",
    step_1: "Customized training and assistance in agroforestry techniques.",
    step_2: "Assistance in native forest restoration techniques.",
    step_3:
      "Access to seeds and seedlings for agroforestry systems and native forest restoration",
    step_4: "Access to new markets for cocoa and other agroforestry products.",
    step_5:
      "Access to digital tools to manage agroforestry systems.",
    step_6:
      "New opportunities to generate income from credits for carbon sequestration.",
    partners: "SUPPORTED BY",
    title: "Agroforestry and Restoration Accelerator",
    content_program:
      "Sabiá supports farmers who remove carbon from the atmosphere by restoring land and establishing agroforestry systems. In Pará, Brazil, the Agroforestry and Restoration Accelerator operates through a collaboration between Amazon and Belterra.",
    photo_credits: "Photo by Kevin Arnold - The Nature Conservancy",
    cta_register: "REGISTER NOW",
  },
  PT: {
    title_1:
      "Descubra novas oportunidades. \n Descubra a agrofloresta. \n Descubra o Sabiá.",
    title_2: "Fácil de usar e desenvolvido para agricultores familiares.",
    cta: "SAIBA MAIS",
    brazil: "PARÁ, BRASIL",
    content_h2:
      "O Sabiá ajuda pequenos produtores e produtoras a implantar sistemas agroflorestais e restaurar florestas.",
    content:
      "Produtores que participam do Acelerador de Agroflorestas e Restauração podem utilizar o Sabiá para ter fácil acesso a vários benefícios do programa:",
    step_1:
      "Treinamentos personalizados e assistência em técnicas agroflorestais.",
    step_2: "Assistência em técnicas de restauração de florestas nativas.",
    step_3:
      "Acesso a sementes e mudas para sistemas agroflorestais e restauração com espécies florestais nativas.",
    step_4:
      "Acesso a novos mercados para cacau e outros produtos agroflorestais.",
    step_5:
      "Acesso a ferramentas digitais para gerenciar sistemas agroflorestais.",
    step_6:
      "Novas oportunidades de geração de renda através de créditos pela remoção de carbono da atmosfera.",
    partners: "UM PROJETO APOIADO POR",
    title: "Acelerador de Agroflorestas e Restauração",
    content_program:
      "O Sabiá apoia agricultores que removem carbono da atmosfera por meio da restauração de terras degradadas e estabelecimento de sistemas agroflorestais. No Pará, Brasil, o Acelerador de Agroflorestas e Restauração opera por meio de uma colaboração entre a Amazon e a Belterra.",
    photo_credits: "Foto por Kevin Arnold - The Nature Conservancy",
    cta_register: "INSCREVA-SE AGORA",
  },
};
