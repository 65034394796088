import React from 'react'

const ByomaSobre1 = () => (
  <svg width="53" height="62" fill="none" >
    <g clipPath="url(#clip0_433:5906)">
      <path d="M52.5 23C52.5 10.5 42.3 0.2 29.8 0C29.7 0 29.6 0 29.5 0C18.7 0 9.2 7.7 7 18.4C6.4 21 6.4 23.8 6.8 26.5L0 38.6L7.5 40.7V53H17.5V62H19.5V51H9.5V39.2L3 37.4L8.9 26.9L8.8 26.6C8.4 24 8.4 21.4 8.9 18.9C11 8.9 19.8 1.9 29.8 2C41.2 2.1 50.5 11.6 50.5 23V26.6C50.5 34.6 46.6 42.1 39.9 46.7L39.5 47V62H41.5V48C48.4 43 52.5 35.1 52.5 26.6V23Z" fill="#FFFAF0"/>
      <path d="M45.6 27L41 17.8L33 20.4L35.1 24.4L30.2 31L35.4 38V35H41L45.6 27ZM40 20.2001L43.1 26.4L38.7 26.7001L36 21.6L40 20.2001ZM35.5 25L36.5 26.9L35.5 27V25ZM34.2 33L32.7 31L34.2 29L42.5 28.4L39.9 33H34.2Z" fill="#FFFAF0"/>
      <path d="M29 16.8L37.3 18.2L39 8.1L36.6 10.5L34 7H25.9L20.2 16.1L25.6 21.5L29 16.8ZM33 9L36.1 13.2L35.7 15.9L32.6 15.4L32.8 15.2L28.5 9H33ZM22.8 15.8L26.6 9.7L29.1 13.2L25.4 18.4L22.8 15.8Z" fill="#FFFAF0"/>
      <path d="M23.2 19.8L14.4 21.3L16.3 23.2001L14.4 27L19 35H29.6V27H25.4L23.2 19.8ZM18.6 22.6L21.8 22.1L22.8 25.6L22.6 25.5L19.3 31.6L16.6 27L18.7 22.8L18.6 22.6ZM27.5 33H20.8L23 29H27.5V33Z" fill="#FFFAF0"/>
    </g>
    <defs>
    <clipPath >
      <rect width="52.5" height="62" fill="white"/>
    </clipPath>
    </defs>
  </svg>
)

export default ByomaSobre1
