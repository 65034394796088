import React from 'react'

const ByomaSobre3 = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" >
    <path d="M31 15C22.2 15 15 22.2 15 31C15 39.8 22.2 47 31 47C39.8 47 47 39.8 47 31C47 22.2 39.8 15 31 15ZM31 45C23.3 45 17 38.7 17 31C17 23.3 23.3 17 31 17C38.7 17 45 23.3 45 31C45 38.7 38.7 45 31 45Z" fill="#FFFAF0"/>
    <path d="M33 22H32V19H30V22H29C26.2 22 24 24.2 24 27C24 29.8 26.2 32 29 32H33C34.7 32 36 33.3 36 35C36 36.7 34.7 38 33 38H29C27.3 38 26 36.7 26 35V34H24V35C24 37.8 26.2 40 29 40H30V43H32V40H33C35.8 40 38 37.8 38 35C38 32.2 35.8 30 33 30H29C27.3 30 26 28.7 26 27C26 25.3 27.3 24 29 24H33C34.7 24 36 25.3 36 27V28H38V27C38 24.2 35.8 22 33 22Z" fill="#FFFAF0"/>
    <path d="M42 30H40V32H42V30Z" fill="#FFFAF0"/>
    <path d="M22 30H20V32H22V30Z" fill="#FFFAF0"/>
    <path d="M32 10H30V13H32V10Z" fill="#FFFAF0"/>
    <path d="M32 4H30V8H32V4Z" fill="#FFFAF0"/>
    <path d="M32 0H30V2H32V0Z" fill="#FFFAF0"/>
    <path d="M24.2279 12.1052L22.3803 12.8708L23.1459 14.7184L24.9935 13.9528L24.2279 12.1052Z" fill="#FFFAF0"/>
    <path d="M20.0486 1.97455L18.2008 2.73975L21.759 11.332L23.6068 10.5668L20.0486 1.97455Z" fill="#FFFAF0"/>
    <path d="M17.4836 16.1254L16.0694 17.5396L17.5543 19.0245L18.9685 17.6103L17.4836 16.1254Z" fill="#FFFAF0"/>
    <path d="M14.915 13.4493L13.5008 14.8635L14.8443 16.207L16.2585 14.7928L14.915 13.4493Z" fill="#FFFAF0"/>
    <path d="M9.74992 8.39928L8.33572 9.81348L12.0834 13.5611L13.4976 12.1469L9.74992 8.39928Z" fill="#FFFAF0"/>
    <path d="M11.7936 21.9256L11.028 23.7732L14.0765 25.0364L14.8421 23.1888L11.7936 21.9256Z" fill="#FFFAF0"/>
    <path d="M6.0468 19.6229L5.2814 21.4707L9.25417 23.1163L10.0196 21.2685L6.0468 19.6229Z" fill="#FFFAF0"/>
    <path d="M2.76523 18.2225L1.99963 20.0701L3.66247 20.7591L4.42807 18.9115L2.76523 18.2225Z" fill="#FFFAF0"/>
    <path d="M13 30H11V32H13V30Z" fill="#FFFAF0"/>
    <path d="M9 30H6V32H9V30Z" fill="#FFFAF0"/>
    <path d="M4 30H0V32H4V30Z" fill="#FFFAF0"/>
    <path d="M13.9401 36.9496L9.87491 38.6331L10.6401 40.4809L14.7053 38.7974L13.9401 36.9496Z" fill="#FFFAF0"/>
    <path d="M8.16834 39.3929L1.97821 41.957L2.74361 43.8048L8.93374 41.2407L8.16834 39.3929Z" fill="#FFFAF0"/>
    <path d="M17.5313 43.0053L14.915 45.6216L16.3292 47.0358L18.9455 44.4195L17.5313 43.0053Z" fill="#FFFAF0"/>
    <path d="M13.5986 47.0505L11.548 49.1011L12.9622 50.5153L15.0128 48.4647L13.5986 47.0505Z" fill="#FFFAF0"/>
    <path d="M10.1968 50.3671L8.35837 52.2056L9.77257 53.6198L11.611 51.7813L10.1968 50.3671Z" fill="#FFFAF0"/>
    <path d="M23.1863 47.1553L22.4207 49.0029L24.2683 49.7685L25.0339 47.9209L23.1863 47.1553Z" fill="#FFFAF0"/>
    <path d="M21.8366 50.5357L21.071 52.3833L22.9186 53.1489L23.6842 51.3013L21.8366 50.5357Z" fill="#FFFAF0"/>
    <path d="M20.3282 54.206L18.2228 59.2869L20.0704 60.0525L22.1758 54.9716L20.3282 54.206Z" fill="#FFFAF0"/>
    <path d="M32 49H30V53H32V49Z" fill="#FFFAF0"/>
    <path d="M32 55H30V62H32V55Z" fill="#FFFAF0"/>
    <path d="M38.7967 47.1983L36.9491 47.9639L37.753 49.9038L39.6006 49.1382L38.7967 47.1983Z" fill="#FFFAF0"/>
    <path d="M40.3321 50.959L38.4845 51.7246L39.8243 54.9579L41.6719 54.1923L40.3321 50.959Z" fill="#FFFAF0"/>
    <path d="M42.4881 56.1482L40.6403 56.9136L41.9414 60.0548L43.7892 59.2894L42.4881 56.1482Z" fill="#FFFAF0"/>
    <path d="M44.4161 42.9972L43.0019 44.4114L47.3152 48.7247L48.7294 47.3105L44.4161 42.9972Z" fill="#FFFAF0"/>
    <path d="M50.2043 48.8085L48.7901 50.2227L52.1842 53.6167L53.5984 52.2025L50.2043 48.8085Z" fill="#FFFAF0"/>
    <path d="M48.0469 37.0055L47.2815 38.8533L51.2542 40.4989L52.0196 38.6511L48.0469 37.0055Z" fill="#FFFAF0"/>
    <path d="M53.7222 39.3685L52.9568 41.2163L56.0981 42.5175L56.8635 40.6697L53.7222 39.3685Z" fill="#FFFAF0"/>
    <path d="M58.4939 41.2875L57.7289 43.1355L59.2072 43.7475L59.9722 41.8995L58.4939 41.2875Z" fill="#FFFAF0"/>
    <path d="M54 30H49V32H54V30Z" fill="#FFFAF0"/>
    <path d="M58 30H56V32H58V30Z" fill="#FFFAF0"/>
    <path d="M62 30H60V32H62V30Z" fill="#FFFAF0"/>
    <path d="M54.3063 20.2122L47.1922 23.1582L47.9574 25.006L55.0715 22.06L54.3063 20.2122Z" fill="#FFFAF0"/>
    <path d="M59.2016 18.2595L56.1531 19.5227L56.9187 21.3703L59.9672 20.1071L59.2016 18.2595Z" fill="#FFFAF0"/>
    <path d="M44.8274 15.7801L43.0597 17.5479L44.4739 18.9621L46.2416 17.1943L44.8274 15.7801Z" fill="#FFFAF0"/>
    <path d="M49.604 11.0433L46.1392 14.5081L47.5534 15.9223L51.0182 12.4575L49.604 11.0433Z" fill="#FFFAF0"/>
    <path d="M52.2046 8.38853L50.7904 9.80273L52.2046 11.2169L53.6188 9.80273L52.2046 8.38853Z" fill="#FFFAF0"/>
    <path d="M39.1868 8.49098L36.9283 13.9414L38.7759 14.707L41.0344 9.25658L39.1868 8.49098Z" fill="#FFFAF0"/>
    <path d="M41.9403 1.97276L39.9115 6.8689L41.7591 7.6345L43.7879 2.73836L41.9403 1.97276Z" fill="#FFFAF0"/>
  </svg>
)

export default ByomaSobre3
