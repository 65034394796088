import { useState } from "react";
import * as S from "./select-language-styles";
import languages from "../../shared/translation/languages";
import { LanguageType, useTranslate } from "../../hooks/use-translate";

export const SelectLanguage = () => {
  const [showOptions, setShowOPtions] = useState(false);
  const { language, changeLanguage } = useTranslate();

  const onChangeLanguage = (language: string) => {
    changeLanguage(language as LanguageType);
    setShowOPtions(false);
  };
  return (
    <S.Container>
      <S.HeaderSelect onClick={() => setShowOPtions(!showOptions)}>
        {language}
        {showOptions ? <p>&nbsp;&#x25B2;</p> : <p>&nbsp;&#9660;</p>}
      </S.HeaderSelect>
      <S.WrapperOptions visible={showOptions}>
        {languages.map((item, key) => (
          <S.ItemOption
            key={key}
            onClick={() => onChangeLanguage(item.language)}
          >
            {item.label}
          </S.ItemOption>
        ))}
      </S.WrapperOptions>
    </S.Container>
  );
};
