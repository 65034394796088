import CocoaSmall from "../../assets/images/jpg/sabia-cacoa-responsive.jpg";
import Cocoa from "../../assets/images/jpg/sabia-cacoa.jpg";
import ArrowDownBold from "../../assets/images/svg/ArrowDownBold";
import Ciclo from "../../assets/images/svg/Ciclo";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./home-styles";
import { HomeTranslate } from "./home-translate";

import ByomaSobre1 from "../../assets/images/svg/ByomaSobre1";
import ByomaSobre2 from "../../assets/images/svg/ByomaSobre2";
import ByomaSobre3 from "../../assets/images/svg/ByomaSobre3";
import ByomaSobre4 from "../../assets/images/svg/ByomaSobre4";
import ByomaSobre5 from "../../assets/images/svg/ByomaSobre5";
import ByomaSobre6 from "../../assets/images/svg/ByomaSobre6";

import LogoAmazon from "../../assets/images/png/partner-amazon-logo-white.png";
import LogoBovcontrol from "../../assets/images/png/partner-bovcontrol-logo-white.png";
import LogoTheNature from "../../assets/images/png/partner-the-nature-logo-white.png";
import LogoWorldAgro from "../../assets/images/png/partner-world-agro-logo-white.png";
import { CardBannerRegister } from "../../components/CardBannerRegister";

export const HomePage = () => {
  const { translate } = useTranslateDocument(HomeTranslate);

  const TheStep = [
    {
      image: ByomaSobre1,
      content: "step_1",
    },
    {
      image: ByomaSobre2,
      content: "step_2",
    },
    {
      image: ByomaSobre3,
      content: "step_3",
    },
    {
      image: ByomaSobre4,
      content: "step_4",
    },
    {
      image: ByomaSobre5,
      content: "step_5",
    },
    {
      image: ByomaSobre6,
      content: "step_6",
    },
  ];

  const PartnersLogo = [
    {
      logo: LogoTheNature,
      url: "https://www.tnc.org.br/",
    },
    {
      logo: LogoWorldAgro,
      url: "https://www.worldagroforestry.org/country/Brazil",
    },
    {
      logo: LogoAmazon,
      url: "https://www.amazon.com/",
    },
    {
      logo: LogoBovcontrol,
      url: "https://platform.bovcontrol.com/",
    },
  ];

  return (
    <S.Container>
      <S.CardBanner>
        <S.ContentCard>
          <S.WrapperInfo>
            <S.LabelOne>{translate("title_1")}</S.LabelOne>
            <S.LabelTwo>{translate("title_2")}</S.LabelTwo>
            <S.ButtonLearn to={"about-what-knew"} smooth duration={500}>
              {translate("cta")}
              <ArrowDownBold />
            </S.ButtonLearn>
          </S.WrapperInfo>
          <S.WrapperBackground>
            <S.BackgroundAppProgram />
            <S.BackgroundCarbon>
              <Ciclo />
            </S.BackgroundCarbon>
          </S.WrapperBackground>
        </S.ContentCard>
      </S.CardBanner>
      <S.CardBannerHelpInformtion id="about-what-knew">
        <S.ContentCardBannerHelpInformation>
          <S.BackgroundCocoa>
            <img src={Cocoa} className="noResponsive" />
            <img src={CocoaSmall} className="responsive" />
          </S.BackgroundCocoa>
          <S.WrapperMap>
            <S.ImageBrasil />
            <S.Circle />
            <S.CountryText>{translate("brazil")}</S.CountryText>
            <S.WrapperLine>
              <S.Line />
              <S.Circle />
            </S.WrapperLine>
          </S.WrapperMap>
          <S.WrapperInfoProgram>
            <S.InfoOne>{translate("content_h2")}</S.InfoOne>
            <S.InfoTwo>{translate("content")}</S.InfoTwo>
            <S.WrapperBoxUseCase>
              {TheStep.map((item, key) => (
                <S.BoxUseCase>
                  {item.image()}
                  <S.DescriptionUseCase>
                    {translate(item.content)}
                  </S.DescriptionUseCase>
                </S.BoxUseCase>
              ))}
            </S.WrapperBoxUseCase>
          </S.WrapperInfoProgram>
        </S.ContentCardBannerHelpInformation>
      </S.CardBannerHelpInformtion>
      <S.CardBannerProgram>
        <S.ContentCardBannerProgram>
          <S.WrapperDescriptionProgram>
            <S.TitleProgram>{translate("title")}</S.TitleProgram>
            <S.DescriptionProgram>
              {translate("content_program")}
            </S.DescriptionProgram>
          </S.WrapperDescriptionProgram>
          <S.WrapperImageFarmer>
            <S.ImageFarmer />
          </S.WrapperImageFarmer>
        </S.ContentCardBannerProgram>
      </S.CardBannerProgram>
      <CardBannerRegister />
    </S.Container>
  );
};
