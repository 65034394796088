import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    margin-left: 20px;
`;

export const HeaderSelect = styled.button`
    display: flex;
    background-color: transparent;
    border-width: 0px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 50px;
`;

interface WrapperOptionsProps {
    visible: boolean
}

export const WrapperOptions = styled.div<WrapperOptionsProps>`
    display: ${({visible}) => visible ? 'flex': 'none'};
    position: absolute;
    flex-direction: column;
    margin-top: 50px;
    padding: 6px 18px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px #3332;
    background-color: #fff;
`;

export const ItemOption = styled.button`
    background-color: transparent;
    border-width: 0px;
    padding: 3px 2px;
    font-size: 1rem;
    cursor: pointer;
`;