import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: var(--greenByoma);
  justify-content: center;
  align-items: center;
`;

export const ContentCardBannerNextRegister = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1160px;
`;

export const TitleNextRegister = styled.h2`
  color: var(--yellow);
  font-size: 38px;
  left: 0.3px;
  font-weight: 400;

  @media (max-width: 768px) {
    margin-left: 18px;
    margin-bottom: 40px;
  }
`;

export const ButtonRegister = styled(Link)`
  width: 220px;
  height: 60px;
  padding: 0 10px;
  border-radius: 30px;
  background-color: var(--yellow);
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  color: var(--brownByoma);
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
`;
