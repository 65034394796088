export const AboutUsTranslate = {
  EN: {
    start: "Start >",
    title: "About Sabiá",
    photo_credits: "Photo by Kevin Arnold - The Nature Conservancy",
    main_content:
      "<p>Sabiá is an app built for family farmers who wish to implement agroforestry systems and restore native vegetation.<br /><br /> Sabiá’s objective is to connect small farmers with services like training, technical assistance, and digital resources that are available through the Agroforestry and Restoration Accelerator.<br/><br/> Through Sabiá, participating farmers can generate income and – at the same time – restore forest areas and remove carbon. A sustainable cycle, where everyone wins: the community, the forest, and society.</p>",
    why_sabia: "Why Sabiá?",
    about_sabia:
      "<p>Sabiá is named for the national bird of Brazil, which inhabits the edges of woods and orchards and represents hope for the Brazilian people. Much like the national bird, Sabiá calls on families that live at the forest’s edge to find hope in restoring nature.<br /><br /> Sabiá can help family farmers fly higher by using income from agroforestry systems to support their families and communities.</p>",
    partners: "SUPPORTED BY",
    banner_title:
      "An all-in-one tool to help farmers implement agroforestry systems and enhance their income.",
    program_title: "Our programs",
    program_subtitle: "The Agroforestry and Restoration Accelerator",
    program_content:
      "The Agroforestry and Restoration Accelerator helps small farmers create agroforestry systems by planting cocoa and other crops, and restoring native vegetation in part of their properties. These systems can provide a sustainable source of income for participating farmers while sequestering carbon. Farmers can apply for enrollment in the Agroforestry and Restoration Accelerator using their Sabiá account.",
    cycle_title: "How does Sabiá work?",
    cycle_item_title_1: "Sign up with Sabiá",
    cycle_item_subtitle_1:
      "Submit your contact information to receive more information on the Agroforestry and Restoration Accelerator and be notified of introductory workshops in your area.",
    cycle_item_title_2: "Decide if the Agroforestry and Restoration Accelerator is for you",
    cycle_item_subtitle_2:
      "Find out more about the opportunities available through the Agroforestry and Restoration Accelerator by attending a workshop in your area, talking to other participating farmers, and simulating your return with Sabiá.",
    cycle_item_title_3: "Apply to enroll in the program",
    cycle_item_subtitle_3:
      "During the workshops field technicians can help you complete an application to the Agroforestry and Restoration Accelerator. Alternatively, you can register yourself through Sabiá. Your application will be approved if you meet all eligibility requirements for the program.",
    cycle_item_title_4:
      "Receive customized training and assistance in agroforestry techniques",
    cycle_item_subtitle_4:
      "The Agroforestry and Restoration Accelerator offers several in-person training sessions to show you how to properly plant and manage agroforestry systems. You will also receive a personal visit from a technician to help you plan for agroforestry systems and native forest restoration on your property.",
    cycle_item_title_5: "Plant agroforestry systems and restore native forest",
    cycle_item_subtitle_5:
      "The Agroforestry and Restoration Accelerator provides support to participating farmers. Eligible producers will have access to inputs such as seedlings and fertilizer, and services such as preparation of the planting area and technical assistance.",
    cycle_item_title_6: "Earn income as your trees grow",
    cycle_item_subtitle_6:
      "Sabiá will help connect you to buyers for cocoa and other agroforestry products in markets that recognize products of responsible and sustainable origin, thus ensuring better value-added opportunities.",
    cycle_item_title_7: "See results for yourself and the planet",
    cycle_item_subtitle_7:
      "Agroforestry systems and restored native forest can contribute to a more productive, profitable farm while removing carbon from the air, creating a healthier environment and mitigating global climate change.",
    cta_to_simulator_title: "Still wondering if it's worth it?",
    cta_to_simulator_href: "Use the simulator here ",
    cta_to_simulator_text:
      "to see how much your property can earn by implementing an agroforestry system.",
    faq__help_title: "Learn more",
    faq__help_item1: "Get your questions answered \nabout Sabiá",
    faq__help_item2: "Register now and receive the latest news!",
    faq__help_item3a: "Register now and receive \n the latest news!",
  },
  PT: {
    start: "Início >",
    title: "Sobre o Sabiá",
    photo_credits: "Foto de Kevin Arnold - The Nature Conservancy",
    main_content:
      "<p>O Sabiá é um aplicativo desenvolvido para agricultores familiares que desejam implantar sistemas agroflorestais e recuperar a vegetação nativa.<br/><br/>O objetivo do Sabiá é conectar agricultores familiares a benefícios como treinamento, assistência técnica e recursos digitais disponíveis pelo Acelerador de Agroflorestas e Restauração.<br/><br/>Por meio do Sabiá, os agricultores participantes podem gerar renda e – ao mesmo tempo – restaurar florestas e remover carbono. Um ciclo sustentável, onde todos ganham: a comunidade, a floresta e a sociedade.</p>",
    why_sabia: "Por que Sabiá?",
    about_sabia:
      "<p>Sabiá leva o nome da ave brasileira que habita as bordas de matas e pomares e representa a esperança para o povo brasileiro. Tal como o símbolo nacional, o Sabiá convoca as famílias que vivem no entorno da floresta a encontrar esperança na restauração da natureza.<br /><br /> Sabiá pode ajudar os agricultores familiares a voarem mais alto usando a renda dos sistemas agroflorestais para sustentar suas famílias e comunidades.</p>",
    partners: "UM PROJETO APOIADO POR",
    banner_title:
      "Uma ferramenta completa para auxiliar agricultores a implementar sistemas agroflorestais e aumentar sua renda.",
    program_title: "Os programas",
    program_subtitle: "O Acelerador de Agroflorestas e Restauração",
    program_content:
      "O Acelerador de Agroflorestas e Restauração auxilia agricultores familiares do estado do Pará a criar sistemas agroflorestais por meio do plantio de cacau e outras culturas, além de restaurar a vegetação nativa em parte de suas propriedades. Esses sistemas podem fornecer fonte de renda sustentável para os agricultores participantes enquanto sequestram carbono. Os produtores podem se inscrever no Acelerador de Agroflorestas e Restauração usando sua conta Sabiá.",
    cycle_title: "Como funciona o Sabiá?",
    cycle_item_title_1: "Crie uma conta no Sabiá",
    cycle_item_subtitle_1:
      "Envie seus dados de contato para receber mais informações sobre o Acelerador de Agroflorestas e Restauração e ser notificado sobre reuniões de apresentação na sua região.",
    cycle_item_title_2: "Decida se o Acelerador é para você",
    cycle_item_subtitle_2:
      "Conheça as oportunidades do Acelerador de Agroflorestas e Restauração participando de uma reunião de apresentação em sua região, conversando com outros produtores participantes e simulando seu retorno com o Sabiá.",
    cycle_item_title_3: "Inscreva-se para ingressar no programa",
    cycle_item_subtitle_3:
      "Durante as reuniões de apresentação, os técnicos de campo podem ajudá-lo a se inscrever no Acelerador de Agroflorestas e Restauração. Alternativamente, você mesmo pode fazer sua inscrição pelo Sabiá. Sua inscrição será aprovada se você atender a todos os requisitos de elegibilidade para o programa.",
    cycle_item_title_4:
      "Receba treinamento personalizado e assistência em técnicas de produção agroflorestal",
    cycle_item_subtitle_4:
      "O Acelerador de Agroflorestas e Restauração oferece diversos treinamentos presenciais para mostrar a você como plantar e manejar corretamente os sistemas agroflorestais. Você também receberá a visita de um técnico para ajudá-lo a planejar os sistemas agroflorestais e a restauração de florestas nativas em sua propriedade.",
    cycle_item_title_5:
      "Implemente sistemas agroflorestais e restaure a mata nativa",
    cycle_item_subtitle_5:
      "O Acelerador de Agroflorestas e Restauração fornece suporte aos produtores participantes. Produtores elegíveis terão acesso a insumos como mudas e adubo, e serviços como preparo de área para plantio e assistência técnica.",
    cycle_item_title_6: "Seja remunerado à medida que suas árvores crescem",
    cycle_item_subtitle_6:
      "O Sabiá ajudará você a conectar-se a compradores de cacau e outros produtos agroflorestais em mercados que reconhecem produtos de origem responsável e sustentável, assegurando, assim, melhores oportunidades de valor agregado.",
    cycle_item_title_7: "Veja os resultados para você e para o planeta",
    cycle_item_subtitle_7:
      "Os sistemas agroflorestais e a floresta nativa restaurada podem contribuir para uma área agrícola mais produtiva e rentável, ao mesmo tempo que removem o carbono do ar, criando um ambiente mais saudável e mitigando as alterações climáticas globais.",
    cta_to_simulator_title: "Ainda com dúvidas se vale a pena?",
    cta_to_simulator_href: "Use o simulador aqui ",
    cta_to_simulator_text:
      "para ver quanto sua propriedade pode ganhar com a implementação de um sistema agroflorestal.",
    faq__help_title: "Saiba mais",
    faq__help_item1: "Tire suas dúvidas \n sobre o Sabiá",
    faq__help_item2: "Cadastre-se agora \n e receba as novidades!",
    faq__help_item3a: "Cadastre-se agora e receba \n as últimas notícias!!",
  },
};
