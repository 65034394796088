import styled from "styled-components";
import Mockup_Cels from "../../assets/images/svg/mockup_cels.svg";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CardBannerStore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 130px;
    padding-bottom: 100px;
  }
`;
export const ContentCardBannerStore = styled.div`
  width: 1268px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  color: var(--brownByoma);
  font-size: 26px;
  text-align: center;
  font-weight: 200;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const WrapperButtonStore = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonStore = styled.a`
  margin: 0 18px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const ImageStore = styled.img`
  width: 180px;
`;

export const WrapperInfoAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const InfoAction = styled.h2`
  color: var(--offWhite);
  text-align: left;
  margin-left: 100px;
  margin-top: 1px;
  font-family: "Roboto-Regular";
  font-weight: 600;
  font-size: 38px;
  line-height: 38px;
  letter-spacing: -1px;

  @media (max-width: 768px) {
    margin-left: 30px;
    font-size: 32px;
    text-align: center;
  }
`;

export const ImageApplication = styled.img.attrs({
  src: Mockup_Cels,
})`
  @media (max-width: 768px) {
    width: 400px;
  }
`;
