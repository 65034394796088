import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CardBannerAbout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ContentCardBannerAbout = styled.div`
  display: flex;
  max-width: 1268px;
  width: 100%;
  min-height: 600px;
  justify-content: center;
  margin-top: 100px;
  flex-direction: column;
  padding: 0 10px;
  /* align-items: center; */
`;

export const WrapperAbout = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-bottom: 60px;
`;

export const TitleAbout = styled.h2`
  margin-left: 118px;
  width: 70%;
  color: var(--greenByoma);
  font-size: 32px;
`;

interface WrapperQuestionProps {
  isFirst: boolean;
  isLast: boolean;
}

export const WrapperQuestion = styled.div<WrapperQuestionProps>`
  padding: 20px 60px;
  margin-top: -1px;
  ${(props) =>
    !props.isFirst &&
    css`
      border-top: 1px solid var(--gray);
    `}

  ${(props) =>
    !props.isLast &&
    css`
      border-bottom: 1px solid var(--gray);
    `}
    
    @media (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

export const HeaderQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const OrderQuestionNumber = styled.h2`
  color: var(--greenByoma);
  font-size: 26px;
`;

export const TitleQuestion = styled.h2`
  width: 80%;
  margin-left: 30px;
  font-size: 26px;
  color: var(--brownByoma);

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
interface ContentResponseProps {
  visible: boolean;
}

export const ContentResponse = styled.div<ContentResponseProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  padding-left: 107px;

  @media (max-width: 768px) {
    padding-left: 7px;
  }
`;

export const ResponseText = styled.p`
  color: var(--brownByoma);
  font-size: 18px;
  margin-top: -18px;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
