
const languages = [
    {
      language: 'EN',
      label: 'EN',
      i18n: 'en',
      currency: 'USD',
      isDefault: true
    },
    {
      language: 'PT',
      label: 'PT',
      i18n: 'pt-BR',
      currency: 'BRL',
      isDefault: false
    }
  ]
  
  export default languages
  