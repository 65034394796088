export const CardBannerRegisterTranslate = {
  EN: {
    cta_register: "REGISTER NOW",
    title_2: "Easy to use and built for family farmers.",
  },
  PT: {
    title_2: "Fácil de usar e desenvolvido para agricultores familiares.",
    cta_register: "INSCREVA-SE AGORA",
  },
};
