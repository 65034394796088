import React from 'react'

const ArrowDownBold = () => (

<svg viewBox="0 0 512 512">
  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20" d="M112 184l144 144 144-144"/>
</svg>
)

export default ArrowDownBold
