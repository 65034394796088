import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
`;

export const WrapperHeader = styled.div`
  height: 10%;
`;

export const WrapperBody = styled.div`
  display: flex;
  flex: 1;
  height: 70%;
`;

export const WrapperFooter = styled.div`
  display: flex;
  /* flex:1; */
  /* width: 100%; */
  /* height: 20%; */
  justify-content: center;
  background-color: var(--brownByoma);
`;
