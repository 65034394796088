import { Link } from "react-scroll";
import styled from "styled-components";
import FarmerSabia from "../../assets/images/jpg/farmers-sabia.jpg";
import Accelerator from '../../assets/images/svg/logo_acelerador.svg';
import BrazilMap from "../../assets/images/png/brasil-map.png";
import AppBanner from "../../assets/images/png/sabia-app-banner.png";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CardBanner = styled.div`
  display: flex;
  width: 100%;
  min-height: 800px;
  background-color: var(--yellow);
  justify-content: center;
`;

export const ContentCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 1160px;
`;

export const WrapperInfo = styled.div`
  display: flex;
  position: absolute;
  width: 280px;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0px;
  margin-left: 30px;
  z-index: 2;
`;

export const LabelOne = styled.h2`
  font-size: 38px;
  font-weight: 600;
  color: var(--brownByoma);
`;

export const LabelTwo = styled.h4`
  font-size: 24px;
  font-weight: 600;
  color: var(--brownByoma);
  margin-top: 6px;
`;

export const ButtonLearn = styled(Link)`
  border-width: 0px;
  background-color: var(--brownByoma);
  width: 185px;
  height: 50px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  justify-content: center;
  align-items: center;

  color: var(--offWhite);
  font-family: "Roboto-Regular";
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;

  margin-top: 40px;

  cursor: pointer;

  svg {
    width: 1.8rem;
  }

  svg path {
    stroke: var(--offWhite);
  }
`;

export const WrapperBackground = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`;

export const BackgroundAppProgram = styled.img.attrs({
  src: AppBanner,
})`
  display: flex;
  position: absolute;
  z-index: 1;
  width: 60%;
  max-width: 600px;
  min-width: 600px;
  height: 600px;
  min-height: 600px;
  bottom: 0;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    position: absolute;
    max-width: 400px;
    min-width: 400px;
    height: 400px;
    min-height: 400px;
  }

  @media (max-width: 580px) {
    position: absolute;
    max-width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
  }

  @media (max-width: 580px) {
    position: absolute;
    max-width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
  }

  @media (max-width: 380px) {
    position: absolute;
    max-width: 200px;
    min-width: 200px;
    height: 200px;
    min-height: 200px;
  }
`;

export const BackgroundCarbon = styled.div`
  > svg {
    width: 100%;
  }

  @media (max-width: 768px) {
    > svg {
    }
  }
`;

export const CardBannerHelpInformtion = styled.div`
  display: flex;
  min-height: 300px;
  background-color: var(--brownByoma);
  margin-top: -10px;
  justify-content: center;
  z-index: 2;
`;

export const ContentCardBannerHelpInformation = styled.div`
  display: flex;
  flex: 1;
  max-width: 1160px;
  align-items: center;
  flex-direction: column;
`;

export const BackgroundCocoa = styled.div`
  /* display: block; */
  width: 100%;

  .noResponsive {
    display: none;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    min-height: 10%;
    transition: all 0.3s ease;
  }

  .responsive {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 90%;
    min-height: 10%;
    transition: all 0.3s ease;
  }

  @media (min-width: 768px) {
    .noResponsive {
      display: block;
    }

    .responsive {
      display: none;
    }
  }
`;

export const WrapperMap = styled.div`
  display: flex;
  position: absolute;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageBrasil = styled.img.attrs({
  src: BrazilMap,
})`
  width: 100px;
`;

export const Circle = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--yellow);
  border-radius: 10px;
  top: 18px;
`;

export const CountryText = styled.h2`
  color: var(--yellow);
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 8px;
  margin-top: 18px;
`;

export const WrapperLine = styled.div`
  display: flex;
  align-items: centert;
  justify-content: center;
  > div {
    &:nth-child(2) {
      margin-top: 255px;
    }
  }
`;

export const Line = styled.div`
  border-left: 2px var(--yellow) solid;
  height: 140px;
`;

export const WrapperInfoProgram = styled.div`
  display: flex;
  margin-top: 200px;
  flex-direction: column;
  align-items: center;
`;

export const InfoOne = styled.h2`
  font-size: 38px;
  text-align: center;
  color: var(--white);
  margin-top: 0;
  font-weight: 400;
  width: 60%;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const InfoTwo = styled.p`
  width: 70%;
  text-align: center;
  color: var(--white);
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  margin-top: 2rem;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const WrapperBoxUseCase = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 70%;

  svg {
    min-width: 60px;
    min-height: 60px;
  }
`;

export const BoxUseCase = styled.div`
  max-width: 250px;
  height: 240px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  @media (min-width: 768px) {
    margin: 0 0 2rem;
    width: 33%;

    p {
      width: 70%;
    }
  }
`;

export const DescriptionUseCase = styled.h3`
  color: var(--offWhite);
  font-size: 16px;
  line-height: 24px;
  left: 0.3px;
  text-align: center;
  width: 85%;
  font-family: "Roboto-Regular";
  font-weight: 400;
`;

export const WrapperPartner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

export const TitlePartner = styled.p`
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 1px;
  text-align: center;
  color: var(--offWhite);
`;

export const WrapperLogoPartner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LogoPartner = styled.img`
  width: 150px;
  margin: 0 30px;

  @media (max-width: 768px) {
    margin-top: 60px;
    flex-direction: column;
  }
`;

export const CardBannerProgram = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ContentCardBannerProgram = styled.div`
  max-width: 1160px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WrapperDescriptionProgram = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 12px;
  margin-right: 80px;
  position: relative;

  @media (max-width: 768px) {
    padding: 90px;
  }
`;

export const TitleProgram = styled.h2`
  width: 90%;
  color: var(--greenByoma);
  font-size: 38px;
  left: 0.3px;
  font-family: "Roboto-Regular";
  font-weight: 400;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const DescriptionProgram = styled.p`
  width: 90%;
  color: var(--greenByoma);
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CreditsPhoto = styled.p`
  position: absolute;
  bottom: 0;
  right: -60px;
  font-size: 12px;

  @media (max-width: 768px) {
    left: 0;
    margin-left: 18px;
  }
`;

export const WrapperImageFarmer = styled.div`
  display: flex;
  flex: 1;
  padding: 130px 0px;
  justify-content: center;
`;

export const ImageFarmer = styled.img.attrs({
  src: Accelerator,
})`
  height: 110%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;
