import { useState } from "react";
import FaqMinus from "../../assets/images/svg/FaqMinus";
import FaqPlus from "../../assets/images/svg/FaqPlus";
import { CardBannerRegister } from "../../components/CardBannerRegister";
import { CardBannerTop } from "../../components/card-banner-top";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./faq-styles";
import { FaqTranslate } from "./faq-translate";
export const FaqPage = () => {
  const { translate } = useTranslateDocument(FaqTranslate);
  const [openQuestion, setOpenQuestion] = useState("");

  const FaqQuestions = [
    {
      quest: "program-1",
      title: "faq_1_title",
      answer: "faq_1_content",
      questNumber: "01",
    },
    {
      quest: "program-2",
      title: "faq_2_title",
      answer: "faq_2_content",
      questNumber: "02",
    },
  ];

  const FaqQuestionsApp = [
    {
      questApp: "app-1",
      title: "faq_app_1_title",
      answer: "faq_app_1_content",
      questNumber: "01",
    },
    {
      questApp: "app-2",
      title: "faq_app_2_title",
      answer: "faq_app_2_content",
      questNumber: "02",
    },
    {
      questApp: "app-3",
      title: "faq_app_3_title",
      answer: "faq_app_3_content",
      questNumber: "03",
    },
    {
      questApp: "app-4",
      title: "faq_app_4_title",
      answer: "faq_app_4_content",
      questNumber: "04",
    },
    {
      questApp: "app-5",
      title: "faq_app_5_title",
      answer: "faq_app_5_content",
      questNumber: "05",
    },
    {
      questApp: "app-6",
      title: "faq_app_6_title",
      answer: "faq_app_6_content",
      questNumber: "06",
    },
    {
      questApp: "app-7",
      title: "faq_app_7_title",
      answer: "faq_app_7_content",
      questNumber: "07",
    },
  ];

  const toggleOpenQuestion = (question: string) => {
    if (openQuestion === question) {
      setOpenQuestion("");
      return;
    }
    setOpenQuestion(question);
  };
  return (
    <S.Container>
      <CardBannerTop subtitle={translate("title")}></CardBannerTop>
      <S.CardBannerAbout>
        <S.ContentCardBannerAbout>
          <S.WrapperAbout>
            <S.TitleAbout>{translate("faq_title")}</S.TitleAbout>
            {FaqQuestions.map((item, key) => (
              <S.WrapperQuestion
                onClick={() => toggleOpenQuestion(item.quest)}
                key={key}
                isFirst={key === 1}
                isLast={key === FaqQuestions.length}
              >
                <S.HeaderQuestion>
                  <S.OrderQuestionNumber>
                    {item.questNumber}
                  </S.OrderQuestionNumber>
                  <S.TitleQuestion>{translate(item.title)}</S.TitleQuestion>
                  {openQuestion === item.quest ? <FaqMinus /> : <FaqPlus />}
                </S.HeaderQuestion>
                <S.ContentResponse visible={openQuestion === item.quest}>
                  <S.ResponseText
                    dangerouslySetInnerHTML={{ __html: translate(item.answer) }}
                  />
                </S.ContentResponse>
              </S.WrapperQuestion>
            ))}
          </S.WrapperAbout>

          <S.WrapperAbout>
            <S.TitleAbout>{translate("faq_app_title")}</S.TitleAbout>
            {FaqQuestionsApp.map((item, key) => (
              <S.WrapperQuestion
                onClick={() => toggleOpenQuestion(item.questApp)}
                key={key}
                isFirst={key === 1}
                isLast={key === FaqQuestionsApp.length}
              >
                <S.HeaderQuestion>
                  <S.OrderQuestionNumber>
                    {item.questNumber}
                  </S.OrderQuestionNumber>
                  <S.TitleQuestion>{translate(item.title)}</S.TitleQuestion>
                  {openQuestion === item.questApp ? <FaqMinus /> : <FaqPlus />}
                </S.HeaderQuestion>
                <S.ContentResponse visible={openQuestion === item.questApp}>
                  <S.ResponseText
                    dangerouslySetInnerHTML={{ __html: translate(item.answer) }}
                  />
                </S.ContentResponse>
              </S.WrapperQuestion>
            ))}
          </S.WrapperAbout>
        </S.ContentCardBannerAbout>
      </S.CardBannerAbout>
      <CardBannerRegister />
    </S.Container>
  );
};
