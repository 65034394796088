import { createContext, useContext, useEffect, useState } from "react";

const LANGUAGE_KEY = "@sabia:language";

export type LanguageType = "EN" | "PT";

interface TranslateProvider {
  children: React.ReactNode;
}

interface TranslateContextProps {
  language: LanguageType;
  changeLanguage: (language: LanguageType) => void;
}

const TranslateContext = createContext({} as TranslateContextProps);

const TranslateProvider = ({ children }: TranslateProvider) => {
  const [language, setLanguage] = useState<LanguageType>("EN");

  useEffect(() => {
    // loadDefaultLanguage();
    loadDefaultLanguage();
  }, []);

  const detectCurrentLanguageBrowser = () => {
    let currentLanguage = navigator && navigator.language;
    if(currentLanguage){
      currentLanguage = currentLanguage.split('-').shift()?.toUpperCase() ?? "";
      setLanguage(currentLanguage as LanguageType)
      changeLanguage(currentLanguage as LanguageType)
      return;
    }
  };

  const loadDefaultLanguage = () => {
    const currentLanguageStorage = localStorage.getItem(LANGUAGE_KEY);
    if (currentLanguageStorage) {
      setLanguage(currentLanguageStorage as LanguageType);
      return;
    }
    detectCurrentLanguageBrowser();
  };

  const changeLanguage = (language: LanguageType) => {
    try {
      localStorage.setItem(LANGUAGE_KEY, language);
      setLanguage(language);
    } catch (error: any) {
      console.warn(error);
    }
  };

  return (
    <TranslateContext.Provider value={{ language, changeLanguage }}>
      {children}
    </TranslateContext.Provider>
  );
};

const useTranslate = () => {
  const context = useContext(TranslateContext);

  if (!context)
    throw new Error("useTranslate must be used within a TranslateProvider");

  return context;
};

export { TranslateProvider, useTranslate };
