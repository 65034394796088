import React from 'react'

const sabiaPartnerAmazonColor = () => (
  <svg width="229.867" height="69.271" viewBox="0 0 229.867 60">
    <g transform="translate(32.34 41.798)">
      <path d="M391.217,329.3c-13.356,9.856-32.719,15.1-49.392,15.1a89.385,89.385,0,0,1-60.335-23.016c-1.251-1.13-.136-2.671,1.368-1.8a121.449,121.449,0,0,0,60.37,16.018,120.1,120.1,0,0,0,46.048-9.423C391.533,325.219,393.426,327.664,391.217,329.3Z" transform="translate(-280.95 -316.921)" fill="#f90" fillRule="evenodd"/>
      <path d="M435.725,322.007c-1.709-2.187-11.292-1.036-15.6-.52-1.3.157-1.506-.984-.33-1.808,7.646-5.37,20.172-3.819,21.627-2.022,1.467,1.813-.385,14.373-7.549,20.367-1.1.921-2.151.429-1.662-.788C433.826,333.209,437.436,324.2,435.725,322.007Z" transform="translate(-319.901 -315.977)" fill="#f90" fillRule="evenodd"/>
    </g>
    <g transform="translate(0 0)">
      <path d="M420.461,265.723v-5.218a1.289,1.289,0,0,1,1.323-1.32l23.385,0a1.3,1.3,0,0,1,1.348,1.317v4.475c-.007.751-.639,1.729-1.76,3.284l-12.114,17.3a25.952,25.952,0,0,1,13.338,2.865,2.388,2.388,0,0,1,1.241,2.036v5.568c0,.766-.841,1.655-1.724,1.192a26.931,26.931,0,0,0-24.708.047c-.813.432-1.664-.443-1.664-1.211v-5.292a6.293,6.293,0,0,1,.871-3.586l14.035-20.135h-12.22A1.3,1.3,0,0,1,420.461,265.723Z" transform="translate(-287.591 -258.157)" fillRule="evenodd"/>
      <path d="M298.866,298.047h-7.115a1.343,1.343,0,0,1-1.273-1.2l.007-36.514a1.34,1.34,0,0,1,1.373-1.314l6.625,0a1.339,1.339,0,0,1,1.293,1.227v4.767h.134c1.727-4.61,4.981-6.763,9.363-6.763,4.449,0,7.239,2.152,9.231,6.763a10.507,10.507,0,0,1,18.064-2.766c2.257,3.074,1.794,7.529,1.794,11.448l-.007,23.043a1.342,1.342,0,0,1-1.373,1.315h-7.1a1.334,1.334,0,0,1-1.276-1.312l0-19.358a46.7,46.7,0,0,0-.2-6.837c-.531-2.46-2.125-3.152-4.182-3.152a4.719,4.719,0,0,0-4.252,3c-.73,1.846-.664,4.918-.664,6.993v19.355a1.342,1.342,0,0,1-1.371,1.315h-7.107a1.335,1.335,0,0,1-1.278-1.312l-.007-19.358c0-4.072.664-10.064-4.382-10.064-5.114,0-4.915,5.838-4.915,10.064l0,19.355A1.343,1.343,0,0,1,298.866,298.047Z" transform="translate(-251.298 -257.892)" fillRule="evenodd"/>
      <path d="M479.034,258.245c10.56,0,16.27,9.068,16.27,20.594,0,11.14-6.307,19.978-16.27,19.978-10.36,0-16.005-9.069-16.005-20.362C463.029,267.082,468.74,258.245,479.034,258.245Zm.065,7.455c-5.246,0-5.577,7.144-5.577,11.6s-.065,13.985,5.513,13.985c5.514,0,5.778-7.684,5.778-12.369a33.762,33.762,0,0,0-1.063-9.682A4.453,4.453,0,0,0,479.1,265.7Z" transform="translate(-299.976 -257.892)" fillRule="evenodd"/>
      <path d="M523.771,298.047h-7.089a1.336,1.336,0,0,1-1.276-1.312l-.012-36.527a1.348,1.348,0,0,1,1.368-1.192l6.6,0a1.354,1.354,0,0,1,1.265,1.022v5.586h.134c1.994-5,4.781-7.377,9.7-7.377a9.327,9.327,0,0,1,8.3,4.3c1.859,2.92,1.859,7.836,1.859,11.371V296.9a1.351,1.351,0,0,1-1.364,1.149H536.12a1.342,1.342,0,0,1-1.267-1.149V277.072c0-4,.465-9.837-4.449-9.837a4.657,4.657,0,0,0-4.119,2.919,16.24,16.24,0,0,0-1.13,6.918v19.66A1.361,1.361,0,0,1,523.771,298.047Z" transform="translate(-314.748 -257.892)" fillRule="evenodd"/>
      <path d="M386.78,280.471c0,2.773.066,5.082-1.332,7.547a5.781,5.781,0,0,1-4.924,3.234c-2.729,0-4.326-2.08-4.326-5.16,0-6.06,5.435-7.161,10.582-7.161Zm7.171,17.347a1.47,1.47,0,0,1-1.68.165,17.374,17.374,0,0,1-4.078-4.74c-3.905,3.978-6.671,5.172-11.73,5.172-5.989,0-10.649-3.7-10.649-11.089A12.085,12.085,0,0,1,373.4,275.7c3.859-1.694,9.251-2,13.378-2.465v-.924c0-1.7.133-3.7-.866-5.159a4.755,4.755,0,0,0-3.994-1.849c-2.714,0-5.125,1.39-5.718,4.271a1.505,1.505,0,0,1-1.235,1.309l-6.9-.747a1.254,1.254,0,0,1-1.062-1.488c1.587-8.366,9.141-10.892,15.91-10.892,3.462,0,7.987.922,10.715,3.543,3.462,3.233,3.129,7.546,3.129,12.245v11.082c0,3.335,1.385,4.8,2.685,6.592a1.342,1.342,0,0,1-.023,1.892c-1.453,1.218-4.034,3.46-5.455,4.725Z" transform="translate(-272.551 -257.754)" fillRule="evenodd"/>
      <path d="M256.865,280.471c0,2.773.067,5.082-1.33,7.547a5.78,5.78,0,0,1-4.925,3.234c-2.728,0-4.326-2.08-4.326-5.16,0-6.06,5.436-7.161,10.581-7.161Zm7.174,17.347a1.471,1.471,0,0,1-1.681.165,17.31,17.31,0,0,1-4.078-4.74c-3.906,3.978-6.671,5.172-11.73,5.172-5.989,0-10.648-3.7-10.648-11.089a12.083,12.083,0,0,1,7.587-11.628c3.859-1.694,9.251-2,13.376-2.465v-.924c0-1.7.134-3.7-.864-5.159a4.756,4.756,0,0,0-3.994-1.849c-2.713,0-5.127,1.39-5.718,4.271a1.505,1.505,0,0,1-1.236,1.309l-6.9-.747a1.253,1.253,0,0,1-1.062-1.488c1.584-8.366,9.139-10.892,15.91-10.892,3.461,0,7.987.922,10.716,3.543,3.46,3.233,3.128,7.546,3.128,12.245v11.082c0,3.335,1.384,4.8,2.684,6.592a1.343,1.343,0,0,1-.022,1.892c-1.454,1.218-4.037,3.46-5.456,4.725Z" transform="translate(-235.902 -257.754)" fillRule="evenodd"/>
    </g>
  </svg >
)

export default sabiaPartnerAmazonColor
