import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 180px;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1160px;
  height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;

  @media (max-width: 768px) {
  }
`;

export const WrapperLogo = styled(Link)`
  max-width: 80px;

  @media (max-width: 768px) {
    >svg {
      width: 200px;
      margin-top: 10px;
    }
  }
`;

export const WrapperMenuHamburguer = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`;

interface WrapperMenuOption {
  visible: boolean;
}

export const WrapperMenuOpition = styled.div<WrapperMenuOption>`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ visible }) => (visible ? "flex" : "none")};
    background-color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 150px;
    z-index: 10;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

interface CustomLinkProps {
  active: boolean;
}

export const CustomLink = styled(Link)<CustomLinkProps>`
  margin: 0 10px;
  text-decoration: none;
  color: var(--colorFont);

  padding: 14px 0px;
  border-bottom: 5px
    ${({ active }) => (active ? `var(--yellow)` : "transparent")} solid;
`;

export const ButtonRegister = styled(Link)`
  display: flex;
  margin-left: 10px;
  text-decoration: none;
  background-color: var(--yellow);
  padding: 0 10px;
  height: 50px;
  width: 200px;
  border-radius: 30px;
  border-width: 0px;
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--brownByoma);


  @media (max-width: 768px) {
    width: 70%;
    margin: 30px 0px 10px 0px;
  }

  > svg {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
`;
